var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form-wrapper',{attrs:{"success-on-validate":"","hide-close-button":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"ma-auto",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0","outlined":""}},[_c('v-toolbar-title',[_vm._v("Evraklar")]),_c('v-spacer'),_c('form-button',{attrs:{"default-value":{
                seciliTur: null,
                aciklama: null,
                dosya: null,
                dosyaData: {base64: null, name: null, type: null},
                ekDosyalar: [],
                ekDosyalarData: []
              },"label":"Evrak Ekle","title":"Evrak Ekle","medium":""},on:{"success":_vm.onAdd},scopedSlots:_vm._u([{key:"default",fn:function({formData,onSuccess,onClose}){return [_c('evrak-ekle-form',{on:{"onSuccess":onSuccess,"onClose":onClose},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1),_c('v-data-table',{attrs:{"items":_vm.localValue,"headers":_vm.headers,"no-data-text":"Ekli evrak yok.","hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"item.ekDosyalar",fn:function({item}){return [_vm._v(" "+_vm._s(item.ekDosyalarData.map(dosya => dosya.name).join(', '))+" ")]}},{key:"item.actions",fn:function({item, index}){return [_c('delete-dialog',{on:{"success":function($event){return _vm.onDelete(index)}}})]}}])})],1)],1)],1),_c('span',{attrs:{"slot":"submit-button"},slot:"submit-button"},[_c('v-btn',{staticClass:"white--text kaydet-vazgec-btn light-blue darken-4 ml-3",on:{"click":_vm.onSubmit}},[_vm._v(" Tamam ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }